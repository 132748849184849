import { graphql, useStaticQuery } from 'gatsby'
import { INewsItem } from '../types'
import { AppRegionEnum } from '../types/app-region.enum'

export const useNewsData = (region: AppRegionEnum): INewsItem[] => {
  const images = useStaticQuery(imagesQuery)
  const news: Record<AppRegionEnum, INewsItem[]> = {
    [AppRegionEnum.Ru]: [
      {
        id: '__BIM-IN-PRACTICE-2024__',
        title: `Конференция «BIM на практике 2024»`,
        to: '/news/bim-in-practice-2024/',
        image: images.bim_in_practice_2024.childImageSharp.gatsbyImageData,
        date: '08.10.2024',
      },
      {
        id: '__PARTNER-BIM-IN-PRACTICE-2024__',
        title: `SIGNAL стал партнером конференции «BIM на практике 2024»`,
        to: '/news/partner-bim-in-practice-2024/',
        image:
          images.partner_bim_in_practice_2024.childImageSharp.gatsbyImageData,
        date: '09.09.2024',
      },
      {
        id: '__SIGNAL-VOSHEL-V-REESTR-ROSSIJSKOGO-PO-MINCIFRY__',
        title: `SIGNAL вошел в реестр российского ПО Минцифры`,
        to: '/news/signal-voshel-v-reestr-rossijskogo-po-mincifry/',
        image:
          images.signal_voshel_v_reestr_rossijskogo_po_mincifry.childImageSharp
            .gatsbyImageData,
        date: '02.09.2024',
      },
      {
        id: '__SREDA-OBSHIH-DANNYH-DLYA-CIFROVIZACII-STROITELSTVA__',
        title: `Среда общих данных - первый шаг к цифровизации строительства`,
        to: '/news/sreda-obshih-dannyh-dlya-cifrovizacii-stroitelstva/',
        image:
          images.sreda_obshih_dannyh_dlya_cifrovizacii_stroitelstva
            .childImageSharp.gatsbyImageData,
        date: '20.08.2024',
      },
      {
        id: '__ONLINE-CONFERENCE-SIGNAL-DOCS__',
        title: `Онлайн конференция SIGNAL\nКлиентский опыт в использовании SIGNAL DOCS`,
        to: '/news/online-conference-signal-docs/',
        image:
          images.online_conference_signal_docs.childImageSharp.gatsbyImageData,
        date: '16.07.2024',
      },
      {
        id: '__DEV-DIGEST-Q2-2024__',
        title: 'Квартальные обновления SIGNAL | Q2 2024',
        to: '/news/dev-digest-Q2-2024/',
        image: images.dev_digest_Q2_2024.childImageSharp.gatsbyImageData,
        date: '03.07.2024',
      },
      {
        id: '__BIM-NA-STROYKE__',
        title: 'BIM на стройке: не хайп, а рабочая история',
        to: '/news/bim-na-stroyke/',
        image: images.bim_na_stroyke.childImageSharp.gatsbyImageData,
        date: '26.06.2024',
      },
      {
        id: '__DOCS-AND-TANGL-INTEGRATION__',
        title: 'BIM-платформа Tangl интегрирована с SIGNAL DOCS',
        to: '/news/docs-and-tangl-integration/',
        image:
          images.docs_and_tangl_integration.childImageSharp.gatsbyImageData,
        date: '22.05.2024',
      },
      {
        id: '__BIM360-VS-SIGNAL-DOCS__',
        title: `Как и чем заменить Autodesk BIM\u00A0360?`,
        to: '/news/BIM360-vs-SIGNAL-DOCS/',
        image: images.BIM360_vs_SIGNAL_DOCS.childImageSharp.gatsbyImageData,
        date: '20.05.2024',
      },
      {
        id: '__DIGITAL-ENGINEER-NEW-ROLE-IN-CONSTRUCTION__',
        title:
          'Цифровой инженер: новая незаменимая роль на строительном объекте',
        to: '/news/digital-engineer-new-role-in-construction/',
        image:
          images.digital_engineer_new_role_in_construction.childImageSharp
            .gatsbyImageData,
        date: '19.03.2024',
      },
      // {
      //   id: '__6-PRICHIN-ISPOLSOVAT-INSPECTION__',
      //   title:
      //     '6 причин цифровизации строительного контроля с SIGNAL INSPECTION',
      //   to: '/news/6-prichin-ispolsovat-inspection/',
      //   image:
      //     images.prichiny_ispolsovat_inspection.childImageSharp.gatsbyImageData,
      //   date: '22.02.2024',
      // },
      {
        id: '__ WEBINAR-DOCS-SOD__',
        title: 'SIGNAL DOCS: Больше, чем просто среда общих данных',
        to: '/news/webinar-docs-sod/',
        image: images.webinar_docs_sod.childImageSharp.gatsbyImageData,
        date: '19.02.2024',
      },
      {
        id: '__INSTRUMENTI-BIM-MANAGERA-DLYA-AUTOMATIZACII-PROVEROK__',
        title:
          'SIGNAL TOOLS: Checker - автоматизированный BIM-менеджер в вашей компании',
        to: '/news/instrumenti-bim-managera-dlya-automatizacii-proverok/',
        image:
          images.instrumenti_bim_managera_dlya_automatizacii_proverok
            .childImageSharp.gatsbyImageData,
        date: '08.02.2024',
      },
      {
        id: '__WEBINAR-UNIFORMAT-RU__',
        title:
          'Применение Uniformat в SIGNAL, Tangl, IYNO для подсчета объемов работ',
        to: '/news/webinar-uniformat-ru/',
        image: images.webinar_uniformat_ru.childImageSharp.gatsbyImageData,
        date: '24.01.2024',
      },
      {
        id: '__UNIFORMAT-RUS-FOR-BIM-MODELING__',
        title:
          'Российские разработчики ПО SIGNAL, Tangl и IYNO перевели Uniformat II 2015.',
        to: '/news/uniformat-rus-for-bim-modeling/',
        image:
          images.uniformat_rus_for_bim_modeling.childImageSharp.gatsbyImageData,
        date: '24.01.2024',
      },
      {
        id: '__GREEN-SUCCESS-STORIE__',
        title: 'История успеха использования SIGNAL в ГК GREEN',
        to: '/news/green-success-storie/',
        image: images.green_success_storie.childImageSharp.gatsbyImageData,
        date: '22.11.2023',
      },
      {
        id: '__ONLINE-CONF-SIGNAL-TOOLS__',
        title: 'Запись онлайн-конференции SIGNAL',
        to: '/news/online-conf-signal-tools/',
        image: images.online_conf_signal_tools.childImageSharp.gatsbyImageData,
        date: '01.10.2023',
      },
      {
        id: '__IMPORTOZAMESHENIE-SIGNAL__',
        title: 'Курс на импортоопережение',
        to: '/news/impotozameshenie-signal/',
        image: images.impotozameshenie_signal.childImageSharp.gatsbyImageData,
        date: '19.09.2023',
      },
      {
        id: '__PHOTO360-FOR-REAL-ESTATE__',
        title:
          'Преимущества Фото360 при продаже недвижимости и отслеживания хода ремонтных работ',
        to: '/news/photo360-for-real-estate/',
        image: images.photo360_for_real_estate.childImageSharp.gatsbyImageData,
        date: '08.09.2023',
      },
      {
        id: '__IVAN-GORDIENKO-JOINED__',
        title: 'Гордиенко Иван присоединился к команде SIGNAL',
        to: '/news/ivan-gordienko-joined/',
        image: images.ivan_gordienko_joined.childImageSharp.gatsbyImageData,
        date: '07.08.2023',
      },
      {
        id: '__2023-07-27-SIGNAL-TOOLS-WEBINAR__',
        title:
          'Вебинар “BIM для тех кто в каске. Контроль выполненных строительных работ c помощью AutoCAD”',
        to: '/news/2023-07-27-signal-tools-webinar/',
        image: images.signal_tools_webinar.childImageSharp.gatsbyImageData,
        date: '27.07.2023',
      },
      {
        id: '__TOOLS-FREE__',
        title: 'Мы выпустили бесплатную версию TOOLS - TOOLS FREE',
        to: '/news/tools-free/',
        image: images.tools_free.childImageSharp.gatsbyImageData,
        date: '26.07.2023',
      },
      {
        id: '__2023-06-09-BIM-FORUM__',
        title: 'SIGNAL принял участие в BIM-форуме 2023 в Москве',
        to: '/news/2023-06-09-bim-forum/',
        image: images.bim_forum.childImageSharp.gatsbyImageData,
        date: '09.06.2023',
      },
      {
        id: '__2023-06-07-SIGNAL-MADE-DOCS__',
        title: 'SIGNAL выпустил новый модуль - SIGNAL DOCS',
        to: '/news/2023-06-07-signal-made-docs/',
        image: images.signal_made_docs.childImageSharp.gatsbyImageData,
        date: '07.06.2023',
      },
      {
        id: '__PRICE-TOOLS-PRO__',
        title: 'Повышение стоимости на TOOLS PRO c 01.01.2023',
        to: '/news/2022-12-21-price-tools-pro/',
        image: images.price_tools_pro.childImageSharp.gatsbyImageData,
        date: '21.12.2022',
      },
      {
        id: '__SIGNAL-TOOLS__',
        title: 'SIGNAL TOOLS делится на Lite и PRO',
        to: '/news/2022-11-17-signal-tools/',
        image: images.signal_tools.childImageSharp.gatsbyImageData,
        date: '17.11.2022',
      },
      {
        id: '__EVENT__17.12.2021',
        title: 'Клуб цифрового строительства',
        to: '/news/17-12-2021-ev-dcc',
        image: images.ev_dcc.childImageSharp.gatsbyImageData,
        date: '17.12.2021',
      },
      {
        id: '__HALS__',
        title:
          'Реализация цифрового взаимодействия между всеми участниками строительного проекта ГАЛС Девелопмент',
        to: '/news/2021-11-30-ss-hals',
        image: images.hals.childImageSharp.gatsbyImageData,
        date: '30.11.2021',
      },
      {
        id: '__TOOLS__',
        title: 'Технический вебинар SIGNAL TOOLS',
        to: '/news/2021-10-24-wb-tools',
        image: images.wb_tools.childImageSharp.gatsbyImageData,
        date: '24.10.2021',
      },
      {
        id: '__MRGROUP__',
        title:
          'Цифровой контроль строительства для достижения высокого качества реализации проекта MR Group',
        to: '/news/2021-09-09-ss-mrgroup',
        image: images.mrgroup.childImageSharp.gatsbyImageData,
        date: '09.09.2021',
      },
      // {
      //   id: "__PROMO30__",
      //   title:
      //     locale === "en"
      //       ? "PROMO: Warm construction with SIGNAL"
      //       : "АКЦИЯ: Теплая стройка с SIGNAL",
      //   to: "signal-inspection-promo30",
      //   image: "/warm-construction.png",
      //   date: "30.11.2020",
      // },
    ],
    [AppRegionEnum.Us]: [
      {
        id: '__NOVEMBER-DIGEST-2024__',
        title: 'November Digest SIGNAX',
        to: '/november-digest-2024/',
        image: images.november_digest_2024.childImageSharp.gatsbyImageData,
        date: '06.12.2024',
      },
      {
        id: '__OCTOBER-DIGEST-2024__',
        title: 'October Digest SIGNAX',
        to: '/october-digest-2024/',
        image: images.october_digest_2024.childImageSharp.gatsbyImageData,
        date: '15.10.2024',
      },
      {
        id: '__SEPTEMBER-DIGEST-2024__',
        title: 'September Digest SIGNAX',
        to: '/september-digest-2024/',
        image: images.september_digest_2024.childImageSharp.gatsbyImageData,
        date: '20.09.2024',
      },
      {
        id: '__TOOLS-TURBOCHARGE-WORKSHOP-RECORDING__',
        title:
          'SIGNAX TOOLS: Turbocharge your BIM Projects. Workshop recordings',
        to: '/tools-turbocharge-workshop-recording/',
        image:
          images.tools_turbocharge_workshop_recording.childImageSharp
            .gatsbyImageData,
        date: '16.08.2024',
      },
      {
        id: '__AUGUST-DIGEST-2024__',
        title: 'August Digest SIGNAX',
        to: '/august-digest-2024/',
        image: images.august_digest_2024.childImageSharp.gatsbyImageData,
        date: '13.08.2024',
      },
      {
        id: '__JULY-DIGEST-2024__',
        title: 'July Digest SIGNAX',
        to: '/july-digest-2024/',
        image: images.july_digest_2024.childImageSharp.gatsbyImageData,
        date: '10.07.2024',
      },
      {
        id: '__SANDBOX-ACCESS__',
        title: 'SIGNAX SANDBOX free',
        to: '/sandbox-access/',
        image: images.sandbox_access.childImageSharp.gatsbyImageData,
        date: '30.05.2024',
      },
      {
        id: '__NIKITA-POSPELOV-APPOINTMENT__',
        title: 'Nikita Pospelov became new Global Business Director at SIGNAX',
        to: '/nikita-pospelov-appointment/',
        image:
          images.nikita_pospelov_appointment.childImageSharp.gatsbyImageData,
        date: '08.05.2024',
      },
      {
        id: '__BUILDING-SMART-WEBINAR__',
        title:
          'Webinar video: Unlocking the Value of BIM During Construction Phase',
        to: '/building-smart-webinar/',
        image: images.building_smart_webinar.childImageSharp.gatsbyImageData,
        date: '30.01.2024',
      },
      {
        id: '__WORKFLOW-FOR-CONSTRUCTION-SITE__',
        title:
          'SIGNAX presented unified data workflow for construction site at CTF 2023 Riyadh',
        to: '/workflow-for-construction-site/',
        image:
          images.workflow_for_construction_site.childImageSharp.gatsbyImageData,
        date: '31.10.2023',
      },
      {
        id: '__DIGITAL-SURVEYING-CONSTRUCTION__',
        title: 'Digital Surveying for Construction',
        to: '/digital-surveying-construction/',
        image:
          images.digital_surveying_construction.childImageSharp.gatsbyImageData,
        date: '03.10.2023',
      },
      {
        id: '__BIM-CDE-ADOPTION__',
        title: 'BIM and Сommon Data Environment adoption',
        to: '/bim-cde-adoption/',
        image: images.bim_cde_adoption.childImageSharp.gatsbyImageData,
        date: '08.09.2023',
      },
      {
        id: '__2023-06-08-CTF__',
        title:
          'SIGNAX presented digital construction processes at ctf 2023 DUBAI',
        to: '/2023-06-08-construction-technology-festival/',
        image:
          images.construction_technology_festival.childImageSharp
            .gatsbyImageData,
        date: '08.06.2023',
      },
      {
        id: '__RAMADAN-SPECIAL-OFFER__',
        title: 'SIGNAX Ramadan Special offer',
        to: '/ramadan-special-offer/',
        image: images.ramadan_special_offer.childImageSharp.gatsbyImageData,
        date: '23.03.2023',
      },
      {
        id: '__SIGNAX-TECH-NEXT-CONFERENCE__',
        title: 'SIGNAX took part in TechNext conference 2023',
        to: '/2023-02-22-signax-tech-next-conference/',
        image:
          images.signax_tech_next_conference.childImageSharp.gatsbyImageData,
        date: '22.02.2023',
      },
      {
        id: '__SIGNAX-ROCKED-CTF-KSA__',
        title: 'SIGNAX rocked Construction Technology Festival KSA',
        to: '/2022-12-23-signax-rocked-ctf-ksa/',
        image: images.signax_rocked_ctf_ksa.childImageSharp.gatsbyImageData,
        date: '23.12.2022',
      },
      {
        id: '__AUTODESK-SERVICE-PROVIDER__',
        title: 'SIGNAX becomes Autodesk Service Provider in the MENA region',
        to: '/2022-12-21-autodesk-service-provider/',
        image: images.autodesk_service_provider.childImageSharp.gatsbyImageData,
        date: '21.12.2022',
      },
      {
        id: '__CTF-KSA__',
        title: 'Construction Technology Festival KSA, December 14-15',
        to: '/2022-11-17-ctf-ksa/',
        image: images.ctf_ksa.childImageSharp.gatsbyImageData,
        date: '17.11.2022',
      },
      {
        id: '__BDT__',
        title:
          'SIGNAX Will Participate in BIM & Digital Twin Middle East 2022 Forum',
        to: '/bdt-2022/',
        image: images.bdt.childImageSharp.gatsbyImageData,
        date: '06.09.2022',
      },
      {
        id: '__SIGNAX-ON-CTF__',
        title: 'SIGNAX on Construction Technology Festival',
        to: '/signax-on-ctf',
        image: images.signax_on_ctf.childImageSharp.gatsbyImageData,
        date: '09.06.2022',
      },
    ],
  }

  return news[region]
}

const imagesQuery = graphql`
  query {
    november_digest_2024: file(
      relativePath: { eq: "news/november-digest-2024/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    october_digest_2024: file(
      relativePath: { eq: "news/october-digest-2024/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    bim_in_practice_2024: file(
      relativePath: { eq: "news/bim-in-practice-2024/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    september_digest_2024: file(
      relativePath: { eq: "news/september-digest-2024/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    partner_bim_in_practice_2024: file(
      relativePath: { eq: "news/partner-bim-in-practice-2024/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    signal_voshel_v_reestr_rossijskogo_po_mincifry: file(
      relativePath: {
        eq: "news/signal-voshel-v-reestr-rossijskogo-po-mincifry/preview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    sreda_obshih_dannyh_dlya_cifrovizacii_stroitelstva: file(
      relativePath: {
        eq: "news/sreda-obshih-dannyh-dlya-cifrovizacii-stroitelstva/preview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    tools_turbocharge_workshop_recording: file(
      relativePath: {
        eq: "news/tools-turbocharge-workshop-recording/preview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    online_conference_signal_docs: file(
      relativePath: { eq: "news/online-conference-signal-docs/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    august_digest_2024: file(
      relativePath: { eq: "news/august-digest-2024/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    july_digest_2024: file(
      relativePath: { eq: "news/july-digest-2024/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    dev_digest_Q2_2024: file(
      relativePath: { eq: "news/dev-digest-Q2-2024/preview.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    bim_na_stroyke: file(
      relativePath: { eq: "news/bim-na-stroyke/preview.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    sandbox_access: file(
      relativePath: { eq: "news/sandbox-access/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    docs_and_tangl_integration: file(
      relativePath: { eq: "news/docs-and-tangl-integration/preview.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    BIM360_vs_SIGNAL_DOCS: file(
      relativePath: { eq: "news/BIM360-vs-SIGNAL-DOCS/preview.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    nikita_pospelov_appointment: file(
      relativePath: { eq: "news/nikita-pospelov-appointment/preview.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 716, placeholder: BLURRED)
      }
    }
    digital_engineer_new_role_in_construction: file(
      relativePath: {
        eq: "news/digital-engineer-new-role-in-construction/preview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 716, placeholder: BLURRED)
      }
    }
    webinar_docs_sod: file(
      relativePath: { eq: "news/webinar-docs-sod/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 716, placeholder: BLURRED)
      }
    }
    instrumenti_bim_managera_dlya_automatizacii_proverok: file(
      relativePath: {
        eq: "news/instrumenti-bim-managera-dlya-automatizacii-proverok/preview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 716, placeholder: BLURRED)
      }
    }
    webinar_uniformat_ru: file(
      relativePath: { eq: "news/webinar-uniformat-ru/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    uniformat_rus_for_bim_modeling: file(
      relativePath: { eq: "news/uniformat-rus-for-bim-modeling/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    building_smart_webinar: file(
      relativePath: { eq: "news/building-smart-webinar/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    green_success_storie: file(
      relativePath: { eq: "news/green-success-storie/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    online_conf_signal_tools: file(
      relativePath: { eq: "news/online-conf-signal-tools/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    workflow_for_construction_site: file(
      relativePath: { eq: "news/workflow-for-construction-site/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    impotozameshenie_signal: file(
      relativePath: { eq: "news/impotozameshenie-signal/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    photo360_for_real_estate: file(
      relativePath: { eq: "news/photo360-for-real-estate/preview.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 716, placeholder: BLURRED)
      }
    }
    ivan_gordienko_joined: file(
      relativePath: { eq: "news/ivan-gordienko-joined/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    signal_tools_webinar: file(
      relativePath: { eq: "news/2023-07-27-signal-tools-webinar/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 716, placeholder: BLURRED)
      }
    }
    tools_free: file(relativePath: { eq: "news/tools-free/preview.png" }) {
      childImageSharp {
        gatsbyImageData(width: 716, placeholder: BLURRED)
      }
    }
    bim_forum: file(
      relativePath: { eq: "news/2023-06-09-bim-forum/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 716, placeholder: BLURRED)
      }
    }
    signal_made_docs: file(
      relativePath: { eq: "news/2023-06-07-signal-made-docs/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    price_tools_pro: file(
      relativePath: { eq: "news/2022-12-21-price-tools-pro/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    signal_tools: file(
      relativePath: { eq: "news/2022-11-17-signal-tools/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    ev_dcc: file(
      relativePath: { eq: "news/2021_12_17_ev_dcc/event-preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 716, placeholder: BLURRED)
      }
    }
    hals: file(
      relativePath: { eq: "news/2021_11_30_ss_hals/hals-preview.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    wb_tools: file(
      relativePath: { eq: "news/2021_10_24_wb_tools/webinar-preview.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    mrgroup: file(
      relativePath: { eq: "news/2021_09_09_ss_mrgroup/mrgroup-preview.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 716, placeholder: BLURRED)
      }
    }

    digital_surveying_construction: file(
      relativePath: { eq: "news/digital-surveying-construction/preview.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    bim_cde_adoption: file(
      relativePath: { eq: "news/bim-cde-adoption/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    construction_technology_festival: file(
      relativePath: {
        eq: "news/2023-06-08-construction-technology-festival/preview.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 716, placeholder: BLURRED)
      }
    }
    ramadan_special_offer: file(
      relativePath: { eq: "news/ramadan-special-offer/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    signax_tech_next_conference: file(
      relativePath: {
        eq: "news/2023-02-22-signax-tech-next-conference/preview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    signax_rocked_ctf_ksa: file(
      relativePath: { eq: "news/2022-12-23-signax-rocked-ctf-ksa/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    autodesk_service_provider: file(
      relativePath: {
        eq: "news/2022-12-21-autodesk-service-provider/preview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    ctf_ksa: file(relativePath: { eq: "news/2022-11-17-ctf-ksa/preview.png" }) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    bdt: file(relativePath: { eq: "news/bdt-2022/preview.png" }) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    signax_on_ctf: file(
      relativePath: { eq: "news/signax-on-ctf/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
  }
`
